import { navigate } from "gatsby";
import difference from "lodash/difference";
import { safelyStringToArray } from "../utils";

export const isBrowser = () => typeof window !== "undefined";

export const getUser = () =>
  isBrowser() && localStorage.getItem("gatsbyUser")
    ? JSON.parse(localStorage.getItem("gatsbyUser"))
    : {};

const areGtmInfoChanged = (newUser) => {
  const oldUser = getUser();
  if ({} === oldUser && {} === newUser) {
    return false;
  }
  return (
    {} === newUser ||
    {} === oldUser ||
    newUser.profession !== oldUser.profession ||
    getNewAreasOfExpertise(newUser, oldUser).length > 0
  );
};
const getNewAreasOfExpertise = (newUser, oldUser) => {
  if (!newUser || {} === newUser) {
    return [];
  }
  const newAreas = safelyStringToArray(newUser.areasOfExpertise);
  if (!oldUser || {} === oldUser) {
    return newAreas;
  }
  return difference(newAreas, safelyStringToArray(oldUser.areasOfExpertise));
};
export const setUser = (user) => {
  if (areGtmInfoChanged(user)) {
    gtmPushInfo(user);
  }
  localStorage.setItem("gatsbyUser", JSON.stringify(user));
};

const gtmPushInfo = (user) => {
  const areasOfExpertise = safelyStringToArray(user.areasOfExpertise);
  areasOfExpertise.forEach((area) => {
    window.dataLayer?.push({
      event: "user_info",
      user_ids: user ? user.id : "",
      user_type: user ? user.profession : "",
      user_speciality: area,
    });
  });
  if (areasOfExpertise.length === 0) {
    window.dataLayer?.push({
      event: "user_info",
      user_ids: user ? user.id : "",
      user_type: user ? user.profession : "",
      user_speciality: "",
    });
  }
};
export const gtmPushUserInfo = () => {
  gtmPushInfo(getUser());
};

export const setUserFromUpdateData = (data, jwt) => {
  setUser({
    id: data.data.updateUser.user.id,
    userLastName: data.data.updateUser.user.userLastName,
    userFirstName: data.data.updateUser.user.userFirstName,
    country: data.data.updateUser.user.country,
    profession: data.data.updateUser.user.profession,
    areasOfExpertise: data.data.updateUser.user.areasOfExpertise,
    email: data.data.updateUser.user.email,
    token: jwt,
    progress: data.data.updateUser.user.progress,
    quizzResults: data.data.updateUser.user.quizzResults,
    subscribedEvents: data.data.updateUser.user.subscribedEvents,
    subscribedNewsletter: data.data.updateUser.user.subscribedNewsletter,
    favoriteLanguage: data.data.updateUser.user.favoriteLanguage,
  });
};

export const isLoggedIn = () => {
  const user = getUser();
  return !!user.token;
};

export const logout = () => {
  setUser({});
  window.dataLayer?.push({
    event: "page_info",
    page_connect: "logout",
  });
  navigate("/");
};
