export const downloadFile = (nameFile, fileRef) => {
  const link = document.createElement("a");
  link.download = nameFile;
  link.href = fileRef.localFile.publicURL;
  window.dataLayer?.push({
    event: "click_download",
    page_name: document.title,
    document_name: nameFile,
  });
  link.click(function (e) {
    e.preventDefault();
  });
};

export const formatDate = (rawDate, local = "en-US") => {
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const date = new Date(rawDate);
  if (isNaN(date.getTime())) {
    return "";
  }
  return date.toLocaleDateString(local, options);
};

// convert "15:30:00" to "03:30 p.m."
export const getUsTime = (euroTime) => {
  const hoursMinutes = euroTime.split(":");
  const date = new Date();
  date.setHours(hoursMinutes[0], hoursMinutes[1], 0);
  return date
    .toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
    })
    .toLowerCase()
    .replace(/([ap])m/, "$1.m.");
};

// rawDate as YYYY-MM-DD
export const isTodayOrAfter = (rawDate) => {
  const today = new Date().toISOString().split("T")[0];
  return today >= rawDate;
};

const SEE_MORE_COMPONENTS = [
  "content.see-more",
  "content.see-more-courses",
  "content.see-more-events",
];
export const isFullLength = (content) => {
  return (
    SEE_MORE_COMPONENTS.includes(content.strapi_component) ||
    ("content.image-text" === content.strapi_component &&
      !content.title &&
      !content.Caption &&
      content.full_length)
  );
};
export const isSeeMore = (content) => {
  return SEE_MORE_COMPONENTS.includes(content.strapi_component);
};

const islabelDiseaseAwarness = (label) => {
  const regex = /^[dD]isease[_ ][aA]ware?ness$/;
  return regex.test(label);
};

// convert 'live-events' to 'Live_Events'
export const toGtmLabel = (string) => {
  const regex = /^([a-zA-Z])([a-zA-Z]*)-?([a-zA-Z])?([a-zA-Z]*)?$/;
  const matches = regex.exec(string);
  if (!matches) return "";
  const end = matches[3] ? "_" + matches[3].toUpperCase() + matches[4] : "";
  const gtmlabel = matches[1].toUpperCase() + matches[2] + end;
  if (islabelDiseaseAwarness(gtmlabel)) {
    return "Therapeutic_Area";
  }
  return gtmlabel;
};

export const isVideo = (content) =>
  "content.video-text" === content.strapi_component;

export const safelyStringToArray = (string) =>
  !string || typeof string != "string" || !Array.isArray(JSON.parse(string))
    ? []
    : JSON.parse(string);
