import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { graphql, useStaticQuery } from "gatsby";

function getMetaTags(fullSeo, siteUrl) {
  const tags = [];

  if (fullSeo.metaTitle) {
    tags.push(
      {
        property: "og:title",
        content: fullSeo.metaTitle,
      },
      {
        name: "twitter:title",
        content: fullSeo.metaTitle,
      }
    );
  }
  if (fullSeo.metaDescription) {
    tags.push(
      {
        name: "description",
        content: fullSeo.metaDescription,
      },
      {
        property: "og:description",
        content: fullSeo.metaDescription,
      },
      {
        name: "twitter:description",
        content: fullSeo.metaDescription,
      }
    );
  }
  if (fullSeo.shareImage) {
    const imageUrl =
      (siteUrl || "http://localhost:8000") +
      fullSeo.shareImage.localFile.publicURL;
    tags.push(
      {
        name: "image",
        content: imageUrl,
      },
      {
        property: "og:image",
        content: imageUrl,
      },
      {
        name: "twitter:image",
        content: imageUrl,
      }
    );
  }
  if (fullSeo.article) {
    tags.push({
      property: "og:type",
      content: "article",
    });
  }
  if (fullSeo.noindex) {
    tags.push({
      name: "robots",
      content: "noindex",
    });
  }
  tags.push({ name: "twitter:card", content: "summary_large_image" });

  return tags;
}

export default function SEO({ seo = {} }) {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `);
  const siteUrl = data.site.siteMetadata.siteUrl;
  const lang = seo.linkHrefLang === "es" ? "en" : "es";

  return (
    <>
      <Helmet
        title={seo.metaTitle}
        meta={getMetaTags(seo, siteUrl)}
        htmlAttributes={{ lang }}
      >
        <link
          rel="alternate"
          href={siteUrl + seo.linkAlternateHref}
          hreflang={seo.linkHrefLang}
        />
        <script type="text/javascript">{`
           (function(m, o, n, t, e, r, _){
              m['__GetResponseAnalyticsObject'] = e;m[e] = m[e] || function() {(m[e].q = m[e].q || []).push(arguments)};
              r = o.createElement(n);_ = o.getElementsByTagName(n)[0];r.async = 1;r.src = t;r.setAttribute('crossorigin', 'use-credentials');_.parentNode .insertBefore(r, _);
            })(window, document, 'script', 'https://eservier.com/script/5f221fda-724e-43b2-9490-02b71635721d/ga.js', 'GrTracking');
        `}</script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/uikit/3.2.0/js/uikit.min.js"></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/uikit/3.2.0/js/uikit.min.js"></script>
        <script src="https://cdn.jsdelivr.net/npm/uikit@3.2.3/dist/js/uikit-icons.min.js"></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/uikit/3.2.0/js/uikit.js"></script>
        <script
          type="text/javascript"
          defer="defer"
          src="https://extend.vimeocdn.com/ga/110972162.js"
        ></script>
      </Helmet>
    </>
  );
}

SEO.propTypes = {
  seo: PropTypes.shape({
    metaTitle: PropTypes.string,
    metaDescription: PropTypes.string,
    shareImage: PropTypes.object,
    linkAlternateHref: PropTypes.string,
    linkHrefLang: PropTypes.string,
    article: PropTypes.bool,
  }),
};
